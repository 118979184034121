import { template as template_abbf347d22134a6cbf5bf96f6f20b81a } from "@ember/template-compiler";
const FKLabel = template_abbf347d22134a6cbf5bf96f6f20b81a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
