import { template as template_369f755584844799bfad87ef4adb6d3e } from "@ember/template-compiler";
const WelcomeHeader = template_369f755584844799bfad87ef4adb6d3e(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
