import { template as template_a55506afb5e44dd8a2622ab68021e445 } from "@ember/template-compiler";
const FKControlMenuContainer = template_a55506afb5e44dd8a2622ab68021e445(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
