import { template as template_d075f8c2421944649598b9c110ce7dd7 } from "@ember/template-compiler";
const FKText = template_d075f8c2421944649598b9c110ce7dd7(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
