import { template as template_03496f259d164464a45e42131809207a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_03496f259d164464a45e42131809207a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
